<template>
  <div class="help-home">
    <div class="home-help-search">
      <form action="/search" method="get">
      <div><div class="logo"><img src="../../../assets/images/sl-logo-saas.png"/></div></div>
      <h2>Como podemos ajudar?</h2>
        <u-input name="q" placeholder="Descreva o problema ou dúvida" :before="[{icon: 'search', handler () {}}]" hide-underline inverted-light class="bg-white" v-model="q" />
      </form>
    </div>

    <div v-if="$route.name === 'ajuda.home'" class="modules">
      <ul>
        <li v-for="(module, key) in modules" :key="key">
          <a :href="'/modulos/' + module.id">
            <div>
              <div class="icon">
                <img :src="module.icon" />
              </div>
              <span>{{ module.name }}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <module-topics v-if="$route.name === 'ajuda.module.topics'" />
  </div>
</template>

<script>
import {UInput} from "uloc-vue"
import IconLeilao from '../../../assets/images/modules/leilao.png'
import IconBens from '../../../assets/images/modules/bens.png'
import ModuleTopics from "components/ajuda/components/ModuleTopics";
export default {
  name: "Home",
  data () {
    return {
      q: null,
      modules: [
        {
          name: 'Leilão',
          id: 'leilao',
          icon: IconLeilao
        },
        {
          name: 'Bens',
          id: 'bens',
          icon: IconBens
        },
        {
          name: 'Vistoria',
          id: 'vistoria',
          icon: IconLeilao
        },
        {
          name: 'Remoção',
          id: 'remocao',
          icon: IconLeilao
        },
        {
          name: 'Pessoas',
          id: 'pessoas',
          icon: IconLeilao
        },
        {
          name: 'Processos',
          id: 'processos',
          icon: IconLeilao
        },
        {
          name: 'Documentos',
          id: 'documentos',
          icon: IconLeilao
        },
        {
          name: 'Tarefas',
          id: 'tarefas',
          icon: IconLeilao
        },
        {
          name: 'Marketing',
          id: 'marketing',
          icon: IconLeilao
        },
        {
          name: 'CRM',
          id: 'crm',
          icon: IconLeilao
        },
        {
          name: 'Comitentes',
          id: 'comitentes',
          icon: IconLeilao
        },
        {
          name: 'Arrematante',
          id: 'arrematante',
          icon: IconLeilao
        },
        {
          name: 'Marketplace',
          id: 'marketing',
          icon: IconLeilao
        },
        {
          name: 'Financeiro',
          id: 'financeiro',
          icon: IconLeilao
        },
        {
          name: 'Relatórios',
          id: 'relatorios',
          icon: IconLeilao
        },
        {
          name: 'Configurações',
          id: 'configuracoes',
          icon: IconLeilao
        }
      ]
    }
  },
  components: {
    ModuleTopics,
    UInput
  }
}
</script>
