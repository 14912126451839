<template>
  <div class="module-topics">
    <div v-for="topic in topics" :key="topic.id" class="folder">
      <div class="folder-title">
        <a><i class="fas fa-folder" /> {{ topic.name }} <span class="count">(22)</span></a>
      </div>
      <div class="subfolder">
        <article v-for="i in 7">
          <a>
            <i class="fal fa-file-alt" />
            <span>Primeiros passos para configurar o sistema antes de criar um novo leilão sds dadas adasdsadas das</span>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {listGroups} from "@/domain/project/services/knowledge"

export default {
  name: "ModuleTopics",
  data () {
    return {
      topics: null
    }
  },
  computed: {
    module () {
      return this.$route.params.module
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      listGroups (100, 1, '&parent=' + this.module)
          .then(response => {
            this.topics = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>
