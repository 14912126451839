import http from "@/utils/services/http"

export const listGroups = (limit, page, filtros, exportar = false) => {
    let url = '/api/public/project/knowledge?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

